a[rel~="+agree"]:hover, .force-is-agree, .show-links article a[rel~="+agree"] {
  text-decoration-style: double;
}
a[rel~="-agree"]:hover, .force-isnt-agree, .show-links article a[rel~="-agree"] {
  text-decoration-style: dotted;
}

a[rel~="+accurate"]:hover, .force-is-accurate, .show-links article a[rel~="+accurate"] {
  position: relative;
  
  &::after {
    position: absolute;
    vertical-align: super;
    font-size: x-small;
    content: "✓";
  }
}
a[rel~="-accurate"]:hover, .force-isnt-accurate, .show-links article a[rel~="-accurate"] {
  position: relative;

  &::after {
    position: absolute;
    vertical-align: super;
    font-size: x-small;
    content: "✗";
  }
}
