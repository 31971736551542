.syndication {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .resp-syndication__icon,
  .resp-syndication__link {
    display: inline-block;
  }

  .resp-syndication__link {
    text-decoration: none;
    margin: 0.5em;
  }

  .resp-sharing-button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  }

  .resp-syndication__icon svg {
    width: 1em;
    height: 1em;
    margin-right: 0.4em;
    vertical-align: top;
  }

  .resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle;
  }
}