#drops {
  overflow: clip;
  max-height: 100%;
  max-width: 100%;
  
  div {
    position: absolute;
    aspect-ratio: 1/1;
    border: 1px solid var(--accent);

    width: calc(500px * var(--dropSize));
    border-radius: calc(500px * var(--dropSize));

    transform: translate(-50%, -50%);
    opacity: calc(1 - var(--dropSize));
    overflow: hidden;

    filter: drop-shadow(0px 0px 4px var(--accent));
    z-index: -1;
  }
}